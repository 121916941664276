import { BrowserProvider, Contract, ethers } from "ethers";
import { toast } from "react-toastify";
import { burnAddress } from "../config";

const erc20ABI = [
  "function transfer(address to, uint256 value) public returns (bool)",
];
const erc721ABI = [
  "function transferFrom(address from, address to, uint256 tokenId) public",
];
const erc1155ABI = [
  "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) public",
];

/**
 * Function to shorten an Ethereum address.
 * It returns a formatted address like 0x20e...E7B720.
 * @param {string} address - The Ethereum address.
 * @return {string} The formatted address.
 */
export const formatAddress = (address) => {
  if (!address) {
    return "...";
  }
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 6
  )}`.toUpperCase();
};

/**
 * Function to return the image link or a placeholder for a broken image.
 * @param {string} imageUrl - The image URL.
 * @return {string} The valid image URL or a broken image sign.
 */
export const getImageOrBrokenLink = (imageUrl) => {
  const brokenImagePlaceholder = "/broken.png";
  return imageUrl ? imageUrl : brokenImagePlaceholder;
};

/**
 * Function to convert token amount to readable value using ethers.js.
 * @param {string | number} amount - The raw token amount.
 * @param {number} decimals - The number of decimals for the token.
 * @return {string} Readable token amount.
 */
export const formatTokenAmount = (amount, decimals) => {
  try {
    const formattedAmount = ethers.formatUnits(amount, decimals);
    return Number(formattedAmount).toFixed(2);
  } catch (error) {
    console.error("Error formatting amount:", error);
    return 0;
  }
};

/**
 * Function to search for an ENS name by Ethereum address.
 * @param {string} address - The Ethereum address to search for ENS name.
 * @return {Promise<string|null>} The ENS name if found, otherwise null.
 */
export const lookupEnsByAddress = async (address, rpcUrl) => {
  try {
    if (!address || address.length !== 42) {
      return "Invalid address";
    }

    // const provider = new ethers.JsonRpcProvider(rpcUrl);
    const provider = new ethers.JsonRpcProvider("https://cloudflare-eth.com");

    const ensName = await provider.lookupAddress(address);
    console.log(ensName);

    return ensName || "No ENS connected to this address";
  } catch (error) {
    console.error("Error looking up ENS:", error);
    return "Error occurred";
  }
};

const processTxError = (e) => {
  let errorMessage = "Transaction failed";
  if (e.reason) {
    errorMessage = `Transaction failed: ${e.reason}`;
  } else if (e.data && e.data.message) {
    errorMessage = `Transaction failed: ${e.data.message}`;
  } else if (e.message) {
    errorMessage = `Transaction failed: ${e.message}`;
  }
  return errorMessage;
};

export const burnTokens = async (address, amount, signer) => {
  try {
    const tokenContract = new Contract(address, erc20ABI, signer);
    const gasLimit = await tokenContract?.transfer.estimateGas(
      burnAddress,
      amount
    );
    const tx = await tokenContract.transfer(burnAddress, amount, { gasLimit });
    await tx.wait();
    toast("Token burned successfully");
  } catch (error) {
    toast.error(processTxError(error));
  }
};

export const burnERC721 = async (address, tokenId, signer) => {
  try {
    const tokenContract = new Contract(address, erc721ABI, signer);
    const gasLimit = await tokenContract?.transferFrom.estimateGas(
      await signer.getAddress(),
      burnAddress,
      tokenId
    );
    const tx = await tokenContract.transferFrom(
      await signer.getAddress(),
      burnAddress,
      tokenId,
      { gasLimit }
    );
    await tx.wait();
    toast("Token burned successfully");
  } catch (error) {
    toast.error(processTxError(error));
  }
};

export const burnERC1155 = async (address, tokenId, amount, signer) => {
  try {
    const tokenContract = new Contract(address, erc1155ABI, signer);
    const gasLimit = await tokenContract?.safeTransferFrom.estimateGas(
      await signer.getAddress(),
      burnAddress,
      tokenId,
      amount,
      "0x"
    );
    const tx = await tokenContract.safeTransferFrom(
      await signer.getAddress(),
      burnAddress,
      tokenId,
      amount,
      "0x",
      { gasLimit }
    );
    await tx.wait();
    toast("Token burned successfully");
  } catch (error) {
    toast.error(processTxError(error));
  }
};
