// import erc20Abi from "../abis/erc20.json";
// import erc721Abi from "../abis/erc721.json";
// import erc1155Abi from "../abis/erc1155.json";

export const chain = process.env.ACTIVE_CHAIN || "mainnet";

export const env = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  currentNetwork: process.env.REACT_APP_NETWORK_ID,
  isDevMode: process.env.NODE_ENV === "development",
  infuraKey: process.env.REACT_APP_INFURA_KEY,
  admin: process.env.REACT_APP_ADMIN,
  owner: process.env.REACT_APP_OWNER,
  projectId: process.env.REACT_APP_PROJECT_ID,
  moralisKey: process.env.REACT_APP_MORALIS_KEY,
  projectName: process.env.REACT_APP_PROJECT_NAME,
};

const devChains = [
  {
    chainId: 56,
    hexChainId: "0x38",
    currency: "BNB",
    rpcUrl: "https://bsc-dataseed.binance.org",
    explorerUrl: "https://bscscan.com/",
    ticker: "BNB",
    name: "BSC MAINNET",
    shortName: "BSC ",
  },
];

const productionChains = [
  {
    chainId: 1,
    hexChainId: "0x1",
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  },
];

export const supportedNetworks = env.isDevMode ? devChains : productionChains;

export const burnAddress = "0x000000000000000000000000000000000000dEaD";
