import { useState, useEffect } from "react";
import Moralis from "moralis";
import { env } from "../config";

const API_KEY = env.moralisKey;

Moralis.start({ apiKey: API_KEY });

export const useTokenBalances = (address, chain) => {
  const [tokenBalances, setTokenBalances] = useState([]);
  const [lpBalances, setLpBalances] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokenBalances = async () => {
      try {
        const response = await Moralis.EvmApi.token.getWalletTokenBalances({
          chain,
          address,
        });
        const lpTokens = response.raw.filter((token) =>
          token.symbol.includes("LP")
        );
        setLpBalances(lpTokens);
        setTokenBalances(response.raw);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    if (address) {
      fetchTokenBalances();
    }
  }, [address, chain]);

  return { tokenBalances, lpBalances, loading, error };
};

export const useNftBalances = (address, chain) => {
  const [nftBalances, setNftBalances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNftBalances = async () => {
      try {
        const response = await Moralis.EvmApi.nft.getWalletNFTs({
          chain,
          address,
        });
        const nftWithImages = response?.raw?.result?.map((nft) => {
          let metadata;
          let image = "";

          try {
            metadata = JSON.parse(nft.metadata);
            if (metadata && metadata.imageGateway) {
              image = metadata.imageGateway;
            } else if (metadata && metadata.image) {
              image = metadata.image.replace(
                "ipfs://",
                "https://ipfs.io/ipfs/"
              );
            }
          } catch (error) {
            console.error("Error parsing metadata:", error);
          }

          return {
            ...nft,
            imageLink: image,
          };
        });
        setNftBalances(nftWithImages);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    if (address) {
      fetchNftBalances();
    }
  }, [address, chain]);

  return { nftBalances, loading, error };
};
