import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
// import { Provider } from 'react-redux';
// import { Store } from './redux/store';

// components

// pages
import Home from "./pages/home";

// library_-_styles
import "./styles/aos.css";

// styles
import "./styles/main.css";
import "./styles/flex-system.css";
import "./styles/grid-system.css";
import "./styles/text.css";
import "./styles/spacing.css";
import "./styles/responsive.css";
import "./styles/effect.css";

//
import "./styles/lightslider.css";

// custom_-_js_-_files
import "./scripts/main";
import { env, supportedNetworks } from "./config";

const metadata = {
  name: "VISTA APP",
  description: "Vista recycle bin",
  url: "https://vista.com",
  icons: ["https://vista.com/vista_logo.png"],
};

const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  // enableEIP6963: true, // true by default
  // enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  // rpcUrl: '...', // used for the Coinbase SDK
  // defaultChainId: 1 // used for the Coinbase SDK
});

// 5. Create a AppKit instance
createWeb3Modal({
  ethersConfig,
  chains: supportedNetworks,
  projectId: env.projectId,
  featuredWalletIds: [
    "e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b",
    "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18",
  ],
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {
  return (
    // <Provider store={Store}>

    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>

    // </Provider>
  );
}

export default App;
